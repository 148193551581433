import { setPickupOrdersTable } from './pickUpOrdersConfig';
import { setShipTable } from './shipConfig';
import { setDetailTable } from './detailConfig';

export default function setTableCols (type = 'pickUpOrders') {
  const configs = {
    'pickUpOrders': setPickupOrdersTable.call(this),
    'ship': setShipTable.call(this),
    'detail': setDetailTable.call(this),
  };

  configs[type].forEach(v => {
    if (!v.minWidth) {
      let title = v.label || v.title;

      v.minWidth = `${title.length * 16 + (v.sortable ? 30 : 20)}px`;
    }

    v.tooltip = true;
  });

  return configs[type];
}

