export const executionStatusGroup = [
  {
    label: '全部订单',
    value: '',
    en: 'allOrderNum',
    count: 0,
  },
  {
    label: '待发货',
    value: '0',
    en: 'receivingStsPendingNum',
    count: 0,
  },
  {
    label: '部分发货',
    value: '1',
    en: 'receivingStsPartNum',
    count: 0,
  },
  {
    label: '已发货',
    value: '2',
    en: 'receivingStsAllNum',
    count: 0,
  },
];

export const orderStatusOptions = [
  {
    label: '执行中',
    value: 2,
  },
  {
    label: '已完成',
    value: 5,
  },
  {
    label: '已取消',
    value: 4,
  },
];

export const deliveryStatusOptions = [
  {
    label: '待发货',
    value: 0,
  },
  {
    label: '部分发货',
    value: 1,
  },
  {
    label: '已发货',
    value: 2,
  },
];

export const receiveStatusOptions = [
  {
    label: '待收货',
    value: 1,
  },
  {
    label: '部分收货',
    value: 2,
  },
  {
    label: '已收货',
    value: 3,
  },
];

export const collectionStatusOptions = [
  {
    label: '待收款',
    value: 1,
  },
  {
    label: '部分收款',
    value: 2,
  },
  {
    label: '已收款',
    value: 3,
  },
];

export const invoiceStatusOptions = [
  {
    label: '待开票',
    value: 1,
  },
  {
    label: '部分开票',
    value: 2,
  },
  {
    label: '已开票',
    value: 3,
  },
];
