import {
  orderStatusOptions,
  deliveryStatusOptions,
  receiveStatusOptions,
  collectionStatusOptions,
  invoiceStatusOptions,
} from '@/model/pickUpOrders';

function convertToName (enums, value) {
  let label = '--';

  for (let v of enums) {
    if (Number(v.value) === Number(value)) {
      label = v.label;
      break;
    }
  }

  return label;
}

export function setPickupOrdersTable () {
  const pickupOrdersTable = [
    {
      label: '销售合同',
      prop: 'pcCode',
      tooltip: true,
      minWidth: '150px',
    },
    {
      label: '订单编号',
      prop: 'orderCode',
      tooltip: true,
      minWidth: '150px',
    },
    {
      label: '商品信息',
      prop: 'goodsNameInfo',
      tooltip: true,
      minWidth: '180px',
    },
    {
      label: '订单金额',
      prop: 'orderAmount',
      tooltip: true,
      minWidth: '150px',
    },
    {
      label: '客户联系人',
      prop: 'saleOrderCustomerContactName',
      minWidth: '150px',
    },
    {
      label: '收货信息',
      tooltip: true,
      minWidth: '220px',
      render: (h, { row }) => {
        let info = row.saleContractReceiverResDTO || {};
        return h('span', `${info.name || ''}-${info.phone || ''}-${info.provinceName || ''}${info.cityName || ''}${info.areaName || ''}${info.address || ''}` || '--');
      },
    },
    {
      label: '订单状态',
      prop: 'orderSts',
      tooltip: true,
      minWidth: '100px',
      render: (h, { row }) => {
        return h('span', convertToName(orderStatusOptions, row.orderSts));
      },
    },
    {
      label: '开单时间',
      prop: 'createTime',
      tooltip: true,
      minWidth: '150px',
    },
    {
      label: '发货状态',
      prop: 'receivingSts',
      tooltip: true,
      minWidth: '100px',
      render: (h, { row }) => {
        return h('span', convertToName(deliveryStatusOptions, row.receivingSts));
      },
    },
    {
      label: '收货状态',
      prop: 'arrivalSts',
      tooltip: true,
      minWidth: '100px',
      render: (h, { row }) => {
        return h('span', convertToName(receiveStatusOptions, row.arrivalSts));
      },
    },
    {
      label: '收款状态',
      prop: 'paySts',
      tooltip: true,
      minWidth: '100px',
      render: (h, { row }) => {
        return h('span', convertToName(collectionStatusOptions, row.paySts));
      },
    },
    {
      label: '开票状态',
      prop: 'invoiceSts',
      tooltip: true,
      minWidth: '100px',
      render: (h, { row }) => {
        return h('span', convertToName(invoiceStatusOptions, row.invoiceSts));
      },
    },
    {
      label: '客户抬头',
      prop: 'unit',
      tooltip: true,
      minWidth: '180px',
    },
    {
      label: '更新时间',
      prop: 'lastUpdateTime',
      tooltip: true,
      minWidth: '150px',
    },
    {
      label: '操作',
      minWidth: '120',
      fixed: 'right',
      render: (h, { row }) => {
        let actions = [h('span', {
          class: 'contract-option',
          on: {
            click: () => {
              this.operate('detail', row);
            },
          },
        }, '查看')];

        if (row.orderSts === 2 && row.receivingSts !== 2) { // 0->未发货 1-> 部分发货
          actions.push(h('span', {
            class: 'contract-option',
            on: {
              click: () => {
                this.operate('delivery', row);
              },
            },
          }, '发货'));
        }

        return actions;
      },
    },
  ];

  return pickupOrdersTable;
}
